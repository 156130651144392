<template>
    <KCourse loader-class="MBcont" course-id="2" title="Анатомия тела" :items="items">
        <div class="MBtextcont">
            <p>Пока наши основные детали сохнут, мы воспользуемся этим временем и подготовим заготовки для кисти! Достаточно кропотливый и медитативный процесс… Тут главное не торопиться и не забывать, что это только заготовки. Всю детализацию мы будем делать чуть позже.</p>
            <p>Главное поймите, что кисть — это не сложно, всего можно добиться, прилагая достаточно усилий и любви. Если вы освоите кисть, ваши куклы сразу поднимутся в качестве и станут значительно выразительней. Помните, первое на что смотрит искушённый зритель — это лицо и руки куклы! Так давайте не будем его разочаровывать.</p>
            <p class='nomargin'><b> На этом занятии нам понадобятся:</b></p>
            <ol>
                <li>Чертеж</li>
                <li>Пластик La Doll</li>
                <li>Проволока или скрепки канцелярские 5 см нерифлёные</li>
                <li>Ватный диск</li>
                <li>Клей Момент ПВА супер столяр (в красной или желтой бутылке)</li>
                <li>Кисть синтетическая, плоская</li>
                <li>Карандаш, линейка</li>
                <li>Круглогубцы, плоскогубцы</li>
                <li>Вода</li>
                <li>Стек для мелкой лепки</li>
            </ol>
        </div>

        <VideoView video-id="f5ea67fbe64d4d49b3e15567e5a91536"/>

        <div class="MBtextcont">
            <p class='nomargin'><b>Задание:</b></p>
            <p>Подготовить основу для пальцев, обтянуть пластиком и собрать в кисть.</p>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Готовим трафареты", url:"/mycourses/body/1"},
                { title: "Заготовки из пластилина", url:"/mycourses/body/2"},
                { title: "Подготовка к обтяжке", url:"/mycourses/body/3"},
                { title: "Обтяжка пластиком", url:"/mycourses/body/4"},
                { title: "Достаем пластилин", url:"/mycourses/body/5"},
                { title: "Основа кисти", url:"/mycourses/body/6"},
                { title: "Основа стопы", url:"/mycourses/body/7"},
                { title: "Анатомия руки", url:"/mycourses/body/8"},
                { title: "Анатомия кисти", url:"/mycourses/body/9"},
                { title: "Анатомия ноги", url:"/mycourses/body/10"},
                { title: "Анатомия стоп", url:"/mycourses/body/11"},
                { title: "Анатомия тела", url:"/mycourses/body/12"},
            ],
        }
        }
    }
</script>